import React from 'react';
import './Home.css';
import FAQ from './FAQ';
import { motion, useInView } from 'framer-motion';

const Section = ({ content, sectionVariants, id }) => {
  const ref = React.useRef(null);
  const isMobile = window.innerWidth <= 768;
  const isInView = useInView(ref, {
    once: false,
    amount: 0.3,
    rootMargin: '-100px 0px',
  });

  // Version mobile sans animation
  if (isMobile) {
    return (
      <div className="section-div">
        {content}
      </div>
    );
  }

  // Version desktop avec animation
  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={sectionVariants}
    >
      {content}
    </motion.div>
  );
};

// Nouveau composant ProgrammeItem pour animer chaque élément individuellement
const ProgrammeItem = ({ item, index }) => {
  const ref = React.useRef(null);
  const isMobile = window.innerWidth <= 768;
  const isInView = useInView(ref, {
    once: false,
    amount: 0.1,
  });

  // Désactiver complètement l'animation sur mobile
  if (isMobile) {
    return (
      <div className={`programme-row ${item.reverse ? 'reverse' : ''}`}>
        <div className="programme-image">
          <img src={`${process.env.PUBLIC_URL}${item.image}`} alt={item.title} />
        </div>
        <div className="programme-text">
          <h4>{item.title}</h4>
          <p dangerouslySetInnerHTML={{ __html: item.description }} />
          <p><strong>Heure :</strong> {item.time}</p>
          <p><strong>Lieu :</strong> {item.location}</p>
          <a 
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(item.location)}`} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="gmaps-button"
          >
            Voir sur Google Maps
          </a>
        </div>
      </div>
    );
  }

  // Version desktop avec animation
  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, x: item.reverse ? 100 : -100 }}
      animate={isInView ? { opacity: 1, x: 0 } : { opacity: 0, x: item.reverse ? 100 : -100 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      className={`programme-row ${item.reverse ? 'reverse' : ''}`}
    >
      <div className="programme-image">
      <img src={`${process.env.PUBLIC_URL}${item.image}`} alt={item.title} />
      </div>
      <div className="programme-text">
        <h4>{item.title}</h4>
        <p>{item.description}</p>
        <p><strong>Heure :</strong> {item.time}</p>
        <p><strong>Lieu :</strong> {item.location}</p>
        <a 
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(item.location)}`} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="gmaps-button"
        >
          Voir sur Google Maps
        </a>
      </div>
    </motion.div>
  );
};

function Home() {
  // const placeholderImage = 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Pornic_-_Ch%C3%A2teau_-_01.jpg/800px-Pornic_-_Ch%C3%A2teau_-_01.jpg';

  // Simplifier les variantes d'animation
  const sectionVariants = {
    hidden: { 
      opacity: 0,
      y: 50
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <div className="home-div">
      {/* Titre principal avec animation initiale */}
      <motion.div
        className="title"
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1 style={{ fontFamily: 'var(--romantic-font)' }}>Solène & Guillaume</h1>
      </motion.div>

      {/* Sections avec animations au défilement */}
      {[
        {
          id: 'notre-histoire',
          content: (
            <div className="section-div">
              <h3 className="section-title">Notre petite histoire</h3>
              <div className="section-text center-text">
              Nous nous sommes rencontrés il y a 5 ans à CentraleSupélec. <br/> 
Depuis, il y a eu un Covid, une relation à distance entre la Californie et Munich, un GR20, deux Noëls en famille, l'achat d’un GRAND frigo (investissement stratégique), un trail de 30 km, 1636 raclettes… <br/> 
A présent, nous avons décidé de nous marier. Parce qu’au-delà des péripéties, c’est ensemble que nous voulons construire, rêver et avancer, pour toute la vie.<br/>   
              </div>
            </div>  
          ),
        },
        {
          id: 'info-pratiques',
          content: (
            <div id="info-pratiques" className="section-div">
              <h3 className="section-title">Infos Pratiques</h3>
              <div className="tiles-container">
                <div className="tile">
                  <span className="material-symbols-outlined">favorite</span> 
                  <h3>Qui</h3>
                  <p className="small-text">VOUS, car nous serions heureux de vous avoir à nos côtés pour ce jour si spécial  </p>
                </div>
                <div className="tile">
                  <span className="material-symbols-outlined">event</span> 
                  <h3>Quand</h3>
                  <p className="small-text">Le Samedi 26 Juillet 2025 à partir de 14h30 pour la messe</p>
                </div>
                <div className="tile">
                  <span className="material-symbols-outlined">church</span>
                  <h3>Où</h3>
                  <p className="small-text">D’abord à Pornic puis à Frossay, Pays de Retz, à proximité de Nantes</p>
                </div>
              </div>
            </div>
          ),
        },
        {
          id: 'programme',
          content: (
            <div id="programme" className="section-div">
              <h3 className="section-title">Programme</h3>
              <div className="programme-container">
                {[            {
                    title: "Mariage civil ou Découverte de Pornic",
                    description: `Notre union civile aura lieu dans les anciennes écuries de Pornic, une extension de la mairie. Nous aurons un nombre limité de places, ainsi seuls la famille proche et quelques amis seront conviés. Mais nous nous retrouverons vite !
                    
                    N’hésitez pas à flâner dans le vieux port ou sur le sentier des douaniers pour admirer les cabanes de pêcheurs emblématiques de la côte de Jade. En attendant la messe, profitez-en pour savourer une glace à La Fraiseraie ou une galette en terrasse.`,
                    time: "Samedi 26 Juillet - 9h30",
                    location: "Mairie du CSPO, Pornic",
                    image: "/images/mariage-mairie-cabane-pecheur.jpg",
                    reverse: true
                  },
                  {
                    title: "Messe de mariage",
                    description: `Nous sommes heureux de vous convier à notre messe, au cours de laquelle nous échangerons nos vœux. La cérémonie sera célébrée par Monseigneur Olivier Ribadeau Dumas, parrain de la mariée.
                    L’église se trouve au sommet du village. Plusieurs parkings sont à disposition, à différentes distances ; merci d’anticiper pour les personnes à mobilité réduite, voir FAQ.`,
                    time: "Samedi 26 Juillet - 14h30",
                    location: "Église Saint Gilles, Pornic",
                    image: "/images/mariage-eglise-village-pornic.jpg",
                    reverse: false
                  },
                  {
                    title: "Les Festivités après le Oui",
                    description: `Venez célébrer notre union autour d’un cocktail, diner assis et soirée dansante jusqu’au bout de la nuit.
                    Il faut compter 20min en voiture pour rejoindre Frossay. Pour les non-motorisés, nous avons réservé une navette au départ de la gare de Pornic, voir FAQ.`,
                    time: "Samedi 26 Juillet - 17h",
                    location: "Château de la Rousselière, Frossay",
                    image: "/images/soiree-rousseliere-garden.jpg",
                    reverse: true
                  },
                  {
                    title: "Brunch",
                    description: "Pour prolonger ces merveilleux moments et profiter encore un peu de votre compagnie, nous serions ravis de vous retrouver le lendemain autour d’un café (bien mérité !) et de quelques délicieuses gourmandises.  ",
                    time: "Dimanche 27 Juillet - 11h30",
                    location: "Château de la Rousselière, Frossay",
                    image: 'images/brunch-rousseliere.jpg', // Keep placeholder or specify another image
                    reverse: false
                  },
                ].map((item, index) => (
                  <ProgrammeItem 
                    key={index} 
                    item={item} 
                    index={index}
                  />
                ))}
              </div>
            </div>
          ),
        },
        {
          id: 'liste',
          content: (
            <div id="liste" className="section-div">
              <h3 className="section-title">Liste</h3>
              <div className="section-text center-text">
                <p>Notre plus beau cadeau sera votre présence. <br/> Toutefois, si vous voulez contribuer à la concrétisation de nos futurs projets <br/> une liste est disponible ci-dessous.</p>
                <a href="https://www.ungrandjour.com/fr/solene-et-guillaume" className="gmaps-button">Voir la liste</a>

              </div>
            </div>
          ),
        },
        {
          id: 'faq',
          content: (
            <FAQ />
          ),
        },

      ].map((section) => (
        <Section
          key={section.id}
          id={section.id}
          content={section.content}
          sectionVariants={sectionVariants}
        />
      ))}
    </div>
  );
}

export default Home;