import React, { useState } from 'react';
import './FAQ.css';
import faqList from './faqdata.json';

function FAQ() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      {faqList.faqList.map((item, index) => (
        <div key={index} className="faq-item">
          <div 
            className="faq-question"
            onClick={() => toggleFAQ(index)}
          >
            {item.question}
            <span className="faq-toggle">
              {activeIndex === index ? '-' : '+'}
            </span>
          </div>
          {activeIndex === index && (
            <div className="faq-answer">
              {item.answer.split('\n').map((line, i) => (
                line ? <p key={i}>{line}</p> : null
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default FAQ;